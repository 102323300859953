import React from 'react'
import Layout from '../layouts/layout'

export default function Accessibility() {
	return (
		<Layout title="Accessibility">
			<h1>Accessibility</h1>

			<p>
				We believe that this site should be usable by all, whether
				you're using a phone, computer, screen reader, or just like the
				text really big.{' '}
			</p>

			<p>
				If you have problems, please email us at{' '}
				<a href="mailto:accessibility@whenpigsflybbq.com">
					accessibility@whenpigsflybbq.com
				</a>
				, or call us at{' '}
				<a href="tel:+7602957993">
					<span className="sr-only">Call our offices at </span>
					(760) 295-7993
				</a>
			</p>
		</Layout>
	)
}
